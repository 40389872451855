export default [
  /*{
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
  },*/
  /* ESCRITORIO */
  {
    title: 'Escritorio',
    route: 'escritorio',
    icon: 'HomeIcon',
    slug: 'escritorio',
  },

  /* APLICACIONES */
  {
    header: 'Aplicaciones',
  },
  {
    title: 'Entradas',
    icon: 'EditIcon',
    slug: 'entrada.ver-tipo_entradas',
    children: [
      {
        title: 'Todas las Entradas',
        route: 'entrada',
        slug: 'entrada.ver'
      },
      {
        title: 'Nueva Entrada',
        route: 'entrada-nuevo',
        slug: 'entrada.registrar'
      },
      {
        title: 'Tipo Entradas',
        route: 'tipoEntrada',
        slug: 'tipo_entradas',
      },
    ],
  },
  /*{
    title: 'Noticias',
    icon: 'EditIcon',
    children: [
      {
        title: 'Todas las noticias',
        route: 'noticia',
      },
      {
        title: 'Nueva Noticia',
        route: 'noticia-nuevo',
      },
      {
        title: 'Categorias',
        route: 'categoria',
      },
      {
        title: 'Etiquetas',
        route: 'etiqueta',
      },
    ],
  },*/
  {
    title: 'Noticias',
    icon: 'TvIcon',
    slug: 'noticia.ver-categorias',
    children: [
      {
        title: 'Todas las noticias',
        route: 'noticia',
        slug: 'noticia.ver',
      },
      {
        title: 'Nueva Noticia',
        route: 'noticia-nuevo',
        slug: 'noticia.registrar',
      },
      // {
      //   title: 'Categorias',
      //   route: 'categoria',
      //   slug: 'categorias',
      // },
      {
        title: 'Categorias',
        route: 'categoria-noticia',
        slug: 'categorias',
      },
    ],
  },
  {
    title: 'Convocatorias',
    icon: 'RadioIcon',
    slug: 'convocatoria.ver',
    children: [
      {
        title: 'Todas las convocatorias',
        route: 'convocatorias',
        slug: 'convocatoria.ver',
      },
      {
        title: 'Nueva Convocatoria',
        route: 'convocatoria-nuevo',
        slug: 'convocatoria.registrar',
      },
    ],
  },
  {
    title: 'Paginas',
    icon: 'BookIcon',
    slug: 'pagina.ver',
    children: [
      {
        title: 'Todas las paginas',
        route: 'paginas',
        slug: 'pagina.ver',
      },
      {
        title: 'Nueva pagina',
        route: 'pagina-nuevo',
        slug: 'pagina.registrar',
      },
    ],
  },
  {
    title: 'Eventos',
    icon: 'CalendarIcon',
    slug: 'evento.ver',
    children: [
      {
        title: 'Todos los eventos',
        route: 'eventos',
        slug: 'evento.ver',
      },
      {
        title: 'Nuevo evento',
        route: 'evento-nuevo',
        slug: 'evento.registrar',
      },
      // {
      //   title: 'Nuevo evento Wizard',
      //   route: 'evento-wizard-nuevo',
      // },
    ],
  },
  
  {
    title: 'Biblioteca',
    icon: 'BookOpenIcon',
    slug: 'biblioteca',
    children: [
      {
        title: 'Todos los libros',
        route: 'biblioteca',
        slug: 'biblioteca',
      },
      {
        title: 'Nuevo libro',
        route: 'biblioteca-nuevo',
        slug: 'biblioteca',
      },
    ],
  },
  {
    title: 'Galería',
    icon: 'ImageIcon',
    slug: 'galeria',
    children: [
      {
        title: 'Todas las galerías',
        route: 'galeria',
        slug: 'galeria',
      },
      {
        title: 'Nueva Galería',
        route: 'galeria-nuevo',
        slug: 'galeria',
      },
    ],
  },
  // {
  //   title: 'Inst. Afiliadas',
  //   icon: 'MapIcon',
  //   slug: 'asientos_fiscales',
  //   children:[
  //     {
  //       title: 'Lista',
  //       route: 'fiscalias',
  //       slug: 'asientos_fiscales',
  //     },
  //     {
  //       title: 'Registrar Nueva',
  //       route: 'fiscalia-nuevo',
  //       slug: 'asientos_fiscales',
  //     },

  //   ]
  // },
  {
    title: 'Medios',
    route: 'medios',
    icon: 'CameraIcon',
    slug: 'medios',
  },
  // {
  //   title: 'Organigrama',
  //   //route: 'fiscalia-nuevo',
  //   icon: 'BookOpenIcon',
  //   slug: 'departamentos',
  //   children:[
  //     {
  //       title: 'Departamentos',
  //       route: 'departamentos',
  //       slug: 'departamentos',
  //     },
  //     {
  //       title: 'Organigramas',
  //       route: 'organigramas',
  //       slug: 'departamentos',
  //     },
  //     {
  //       title: 'Nuevo Organigrama',
  //       route: 'organigrama-nuevo',
  //       slug: 'departamentos',
  //     },

  //   ]
  // },
  // {
  //   title: 'Estadisticas',
  //   icon: 'EditIcon',
  //   slug: 'estadisticas',
  //   children: [
  //     {
  //       title: 'Todas las Estadisticas',
  //       route: 'estadistica',
  //       slug: 'estadisticas',
  //     },
  //     {
  //       title: 'Nueva Estadistica',
  //       route: 'estadistica-nuevo',
  //       slug: 'estadisticas',
  //     },
  //     {
  //       title: 'Categorias',
  //       route: 'categoria-estadistica',
  //       slug: 'categorias',
  //     },
  //   ],
  // },
  {
    title: 'Etiquetas',
    route: 'etiqueta',
    icon: 'TagIcon',
    slug: 'etiquetas',
  },
  /*{
    title: 'Configuracion',
    icon: 'SettingsIcon',
    slug: 'tipo_entradas-categorias-etiquetas',
    children: [
      {
        title: 'Tipo Entradas',
        route: 'tipoEntrada',
        slug: 'tipo_entradas',
      },
      {
        title: 'Categorias',
        route: 'categoria',
        slug: 'categorias',
      },
      {
        title: 'Etiquetas',
        route: 'etiqueta',
        slug: 'etiquetas',
      },
    ],
  },*/


  /* PAGINA DE TERCEROS */
  {
    header: 'Pagina - terceros',
  },
  {
    title: 'Transmisiones',
    route: 'transmision',
    icon: 'CircleIcon',
    slug: 'transmisiones',
  },
  {
    title: 'PodCast',
    route: 'podcast',
    icon: 'CircleIcon',
    slug: 'podcasts',
  },
  {
    title: 'Audios',
    route: '#',
    icon: 'HeadphonesIcon',
  },
  {
    title: 'Videos',
    route: 'video',
    icon: 'FilmIcon',
    slug: 'videos',
  },

  /* APARIENCIA */
  {
    header: 'Apariencia',
  },  
  {
    title: 'Sliders',
    //route: '#',
    slug: 'sliders',
    icon: 'ExternalLinkIcon',
    
    children:[
      {
        title: 'Todos los Sliders',
        route: 'sliders',
        slug: 'sliders',
      },
     
      {
        title: 'Nuevo Sliders',
        route: 'slider-nuevo',
        slug: 'sliders',
      },

    ]
  },
  {
    title: 'PopUp',
    //route: '#',
    slug: 'popups',
    icon: 'ExternalLinkIcon',
    slug: 'popups',
    children:[
      {
        title: 'Todos los Popup',
        route: 'popups',
        slug: 'popups',
      },
     
      {
        title: 'Nuevo Popup',
        route: 'popup-nuevo',
        slug: 'popups',
      },

    ]
  },
  {
    title: 'Enlaces',
    //route: '#',
    icon: 'ExternalLinkIcon',
    slug: 'enlaces',
    children:[
      {
        title: 'Todas los Enlaces',
        route: 'enlaces',
        slug: 'enlaces',
      },
      {
        title: 'Todos los Grupo Enlaces',
        route: 'grupo-enlaces',
        slug: 'enlaces',
      },
      {
        title: 'Grupo Enlace',
        route: 'grupo-enlace-nuevo',
        slug: 'enlaces',
      },

    ]
  },
  {
    title: 'Menus',
   
    icon: 'MenuIcon',
    slug: 'menus',
    children:[
      {
        title: 'Todas los Menus',
        route: 'menus',
        slug: 'menus',
      },
      {
        title: 'Nuevo Menu',
        route: 'menu-nuevo',
        slug: 'menus',
      },

    ]
  },


  /* CONFIGURACION */
  {
    header: 'Ajustes - Configuracion',
  }, 
  {
    title: 'Personalizar',
    route: 'personalizar',
    icon: 'SettingsIcon',
    slug: 'personalizar',
  },   
  {
    title: 'Usuarios',
    route: 'usuario',
    icon: 'UsersIcon',
    slug: 'usuarios',
  },
  {
    title: 'Roles & Permisos',
    route: 'rol',
    slug: 'roles',
    icon: 'LockIcon',
  },
  {
    title: 'Backups',
    route: 'backup',
    icon: 'DatabaseIcon',
    slug: 'backups',
  },
  {
    title: 'Auditoria',
    route: 'auditoria',
    icon: 'CheckSquareIcon',
    slug: 'auditoria',
  },
  /*{
    title: 'Auditoria',
    //route: '#',
    icon: 'EyeIcon',
    slug: 'auditoria',
  },*/

]
